import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./Pages/Home";
import OurStory from "./Pages/Ourstory";
import BollywoodKids from "./Pages/Classes/Bollywood";
import ZumbaClasses from "./Pages/Classes/ZumbaClasses";
import Faqs from "./Pages/Faqs";
import NotFound from './Pages/NotFound';
import Contactus from "./Pages/Contactus";
import Blog from "./Pages/Blog";
import ClassSchedule from "./Pages/Classes/Classschedule";
import Locations from "./Pages/Classes/Locations";
import KidsDance from "./Pages/PrivateParties/KidsDance";
import WeddingDance from "./Pages/PrivateParties/WeddingDance";
import PrivateParties from "./Pages/PrivateParties/PrivateParties";
import TeamBuilding from "./Pages/PrivateParties/TeamBuilding";
import Retreats from "./Pages/Corporates/Retreats";
import EmployeeWellness from "./Pages/Corporates/EmployeeWellness";
import Joinus from "./Pages/Joinus";
import Thankyou from "./Pages/Thankyou";
import Pricing from "./Pages/Pricing";
import LandingPage from "./Pages/LandingPage";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};
function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/our-story" element={<OurStory />} />
          <Route path="/faq" element={<Faqs />} />
          <Route path="/zumba-classes" element={<ZumbaClasses />} />
          <Route path="/bollywood-for-kids" element={<BollywoodKids />}/>
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/class-schedule" element={<ClassSchedule />} />
          <Route path="/studio-locations" element={<Locations />} />
          <Route path="/kids-dance-classes-and-parties" element={<KidsDance />} />
          <Route path="/wedding-dance" element={<WeddingDance />} />
          <Route path="/private-parties-and-lessons" element={<PrivateParties />} />
          <Route path="/team-building-workshops" element={<TeamBuilding />} />
          <Route path="/retreats" element={<Retreats />} />
          <Route path="/employee-wellness" element={<EmployeeWellness />} />
          <Route path="/join-us" element={<Joinus />} />
          <Route path="/thank-you" element={<Thankyou />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/landing-page" element={<LandingPage />} />
          {/* Catch-all route for 404 */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}
export default App;