import React from 'react';
import {Helmet} from "react-helmet";
import "./landingpage.css";
import Navbar from "../../Components/Navbar/navbar";
import Footer from "../../Components/Footer/footer";
import { Link } from "react-router-dom";
import Clients from "../../Components/Ourclient/ourclient";
import celebrateimage from "../../assets/images/Morale.webp";
import Workshop from "../../assets/images/diverse.webp";

export default function OurStory() {
    
    return(
        <React.Fragment>
            <Helmet>
                <title>Landing Page</title>
            </Helmet>
            <Navbar />
            <section className="landinghead">
                <h1>A Healthier Workforce = Higher Profits.</h1>
                <p>Get your free wellness ROI report now—see how healthy employees can drive profits!</p>
                <div className="landingheadcta">
                    <Link to="https://calendly.com/danceembassy/15min" target='_blank'>Book a Free Consultation</Link>
                </div>
            </section>
            <section className="celebrate celebratecoloumrevert">
                <div className="row align-items-center g-0">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                        <div className="celebratecotnent">
                            <h2>Wellness ROI Calculator – Measure Your Company’s Wellness Quotient</h2>
                            <p>Is your company’s wellness program maximizing employee productivity and profitability? Take this quick quiz to find out how much your organization could be losing—or gaining—from employee wellness investments!</p>
                            <div className="celebratebtns">
                                <Link to="https://calendly.com/danceembassy/15min" target='_blank'>Book Now</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                        <div className="celebrateimage">
                            <img src={celebrateimage} alt="celebrate" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="employwell">
                <div className="container">
                    <div className="employwellhead">
                        <h2>Employee Well-Being & Productivity</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className='employwellcontent'>
                                <h3>How often do employees in your company report feeling stressed or burnt out?</h3>
                                <ul>
                                    <li>Rarely (Less than 10% of employees)</li>
                                    <li>Occasionally (10-30%)</li>
                                    <li>Frequently (30-50%)</li>
                                    <li>Constantly (More than 50%)</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className='employwellcontent'>
                                <h3>What is your company’s annual employee turnover rate?</h3>
                                <ul>
                                    <li>Below 5%</li>
                                    <li>5-10%</li>
                                    <li>10-20%</li>
                                    <li>Above 20%</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className='employwellcontent'>
                                <h3>How often do employees take sick days due to stress-related issues?</h3>
                                <ul>
                                    <li>Rarely (Less than 3 days per year per employee)</li>
                                    <li>Occasionally (3-6 days)</li>
                                    <li>Frequently (6-10 days)</li>
                                    <li>Constantly (More than 10 days)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="employwell backgroundcolor">
                <div className="container">
                    <div className="employwellhead">
                        <h2>Company’s Wellness Investment</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className='employwellcontent'>
                                <h3>Does your company currently offer any structured wellness programs?</h3>
                                <ul>
                                    <li>Yes, comprehensive programs (fitness, mental health support, nutrition, etc.)</li>
                                    <li>Some programs (fitness or mental health but not both)</li>
                                    <li>Basic offerings (occasional workshops, one-time events)</li>
                                    <li>No structured wellness programs</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className='employwellcontent'>
                                <h3>What percentage of your workforce actively participates in wellness initiatives?</h3>
                                <ul>
                                    <li>75% or more</li>
                                    <li>50-75%</li>
                                    <li>25-50%</li>
                                    <li>Less than 25%</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className='employwellcontent'>
                                <h3>Does your company measure the impact of wellness programs on absenteeism and productivity?</h3>
                                <ul>
                                    <li>Yes, we track and analyze key wellness metrics</li>
                                    <li>Somewhat, we have data but don’t analyze it deeply</li>
                                    <li>No, but we plan to</li>
                                    <li>No, we don’t track it at all</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="employwell">
                <div className="container">
                    <div className="employwellhead">
                        <h2>Financial Impact & ROI</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className='employwellcontent'>
                                <h3>How much does your company spend annually on absenteeism and turnover?</h3>
                                <ul>
                                    <li>Less than $10,000</li>
                                    <li>$10,000 - $50,000</li>
                                    <li>$50,000 - $100,000</li>
                                    <li>More than $100,000</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className='employwellcontent'>
                                <h3>On average, how much do you invest in employee wellness per employee per year?</h3>
                                <ul>
                                    <li>More than $1,000</li>
                                    <li>$500 - $1,000</li>
                                    <li>$100 - $500</li>
                                    <li>Less than $100</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className='employwellcontent'>
                                <h3>What are the biggest barriers to implementing a robust wellness program?</h3>
                                <ul>
                                    <li>Budget constraints</li>
                                    <li>Lack of leadership support</li>
                                    <li>Low employee engagement</li>
                                    <li>Lack of awareness about wellness benefits</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="recharge refocus workshop landingpage">
                <div className="container-fluid">
                    <div className="row g-0 align-items-center">
                        <div className="col-lg-6">
                            <div className="retreatcontent">
                                <h2>Results & Custom Wellness ROI Report</h2>
                                <p><b>Low Wellness Quotient (0-5 points)</b></p>
                                <p>Your company is likely losing significant revenue due to poor employee wellness. Investing in structured wellness programs could save thousands in turnover, absenteeism, and lost productivity. Let’s build a strategy to turn things around!</p>
                                <p><b>Moderate Wellness Quotient (6-10 points)</b></p>
                                <p>Your company is on the right track, but there’s room for improvement. By increasing participation, tracking ROI, and expanding offerings, you can see a stronger financial impact from your wellness initiatives.</p>
                                <p><b>High Wellness Quotient (11+ points)</b></p>
                                <p>Great job! Your company is making wellness a priority, and it’s paying off. Keep refining your strategies, measuring results, and investing in employee well-being to maintain high engagement and profitability.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="retreatimg">
                                <img src={Workshop} alt="Recharege" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="finalcta">
                <div className="container">
                    <div className="finalctacontent">
                        <p>Want a deeper analysis of your company’s wellness ROI? Let’s discuss how you can maximize employee well-being and business profitability with a customized wellness strategy.</p>
                        <div className="landingheadcta">
                            <Link to="https://calendly.com/danceembassy/15min" target='_blank'>Book a Free Consultation</Link>
                        </div>
                    </div>
                </div>
            </section>
            <Clients />
            <Footer />
        </React.Fragment>
    );
}
